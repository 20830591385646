import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'gatsby-theme-husky/src/common/Button';
import Section from 'gatsby-theme-husky/src/common/Section';
import BlockCarousel from 'gatsby-theme-husky/src/components/BlockCarousel';
import RelatedArticleCard from 'gatsby-theme-husky/src/components/RelatedArticleCard';
import PrevArrow from 'gatsby-theme-husky/src/components/PDCarousel/components/prevArrow';
import NextArrow from 'gatsby-theme-husky/src/components/PDCarousel/components/nextArrow';

import { UmbracoRelatedArticles } from '@shared/types/umbraco/compositions';
import { sortArticleByRelevant } from 'gatsby-theme-husky/src/utils/sortByRelevant';

import 'gatsby-theme-husky/src/components/RelatedArticles/RelatedArticles.scss';

const RelatedArticles: FC<UmbracoRelatedArticles.IData> = ({
  title,
  link,
  relatedArticles,
  limit,
  relatedArticlesLinks = [''],
  isCarousel,
  waiSettings,
  color,
  customCards,
  alignCenter,
}) => {
  const articles = relatedArticles?.nodes?.length
    ? sortArticleByRelevant(relatedArticlesLinks, relatedArticles.nodes)
    : [];

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    customPaging: (i) => <Button ariaLabel={`Go to slide ${i + 1}`} variant="icon" />,
    prevArrow: <PrevArrow ariaLabel={waiSettings?.ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={waiSettings?.ariaNext} />,
    responsive: [
      {
        breakpoint: 1176,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const adjustedCustomCards =
    customCards?.map(({ properties }) => ({
      ...properties,
      link: properties?.link?.[0].url,
    })) || [];

  const cardsToRender = articles
    .slice(0, limit || articles.length)
    .concat(adjustedCustomCards as UmbracoRelatedArticles.IArticleCard[]);

  return cardsToRender?.length ? (
    <Section
      name="related-articles"
      intro={title ? `<p class="section__intro-title">${title}</p>` : null}
      color={color}
    >
      <>
        {!isCarousel ? (
          <div
            className={classNames('related-articles-section__grid', {
              [`col-${cardsToRender.length}`]: alignCenter,
            })}
          >
            {cardsToRender.map((card) => (
              <RelatedArticleCard key={card.title} {...card} />
            ))}
          </div>
        ) : null}

        {isCarousel ? (
          <BlockCarousel settings={settings}>
            {cardsToRender.map((card) => (
              <RelatedArticleCard key={card.title} {...card} />
            ))}
          </BlockCarousel>
        ) : null}

        {link?.length ? (
          <div className="related-articles-section__more">
            <Button
              ariaLabel={link[0].properties.ariaLabel}
              to={link[0].properties.link?.[0]?.url}
              variant="primary-link"
              iconSuffix="chevron-right"
              className="related-articles-section__link"
            >
              {link[0].properties.link?.[0]?.name}
            </Button>
          </div>
        ) : null}
      </>
    </Section>
  ) : null;
};

export default RelatedArticles;
